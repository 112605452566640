import styled from "styled-components/macro";
import {
  AppBar as MuiAppBar,
  Grid,
  IconButton as MuiIconButton,
  Typography,
  Box as MuiBox,
  ListItem,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { gray, black, blue } from "../assets/variables/variables.style";

export const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  position: fixed;
  .headerWrapper {
    justify-content: space-between;

    .headerleft {
      color: ${black};
      display: flex;

      .contentBox {
        display: flex;
        align-items: center;
        img {
          width: 160px;
        }
      }
    }

    .headerRight {
      display: flex;
      align-items: center;

      .googleAds {
        display: flex;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: ${gray};
          &:hover {
            color: ${blue};
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const HeaderUserContainer = styled(Grid)`
  display: inline-flex;
  margin: 6px 10px 0px 20px;
  width: auto;
  .userIcon {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
`;

export const HeaderUserTextTitle = styled(Typography)`
  color: ${gray};
  font-size: 0.7125rem;
  text-align: right;
`;

export const HeaderUserText = styled(Typography)`
  color: ${gray};
  font-size: 0.7125rem;
  display: block;
  padding: 1px;
  text-align: right;
`;

export const Brand = styled(ListItem)<{
  button?: boolean;
  component?: React.ReactNode;
  to?: string;
}>`
  min-height: 0px;
  padding-left: 0;
  //padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: inherit;
  }
`;

export const Box = styled(MuiBox)(spacing);

export const HeaderText = styled(Typography)`
  font-weight: 500;
  color: ${black};
  font-size: 1.5rem;
  padding: 0 15px;
`;
