import axios from "../utils/axios";

import {
  GetClientIdResponse,
  ResetPasswordType,
  SignInType,
  SignUpType,
} from "../types/auth";
import { AxiosResponse } from "axios";
import ApiUtil from "../utils/apiUtil";

export default class AuthService {
  async signIn(credentials: SignInType) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/sign-in", credentials)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          reject(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async signUp(credentials: SignUpType) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/sign-up", credentials)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          reject(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async resetPassword(credentials: ResetPasswordType) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/reset-password", credentials)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          reject(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getClientId(
    customerId: string,
    managerId: string
  ): Promise<GetClientIdResponse> {
    let res: AxiosResponse;
    if (managerId) {
      res = await ApiUtil.SendAsyncWithoutToken<GetClientIdResponse>({
        url: `/api/v2/customers/${customerId}/clients?managerId=${managerId}`,
        method: "GET",
      });
    } else {
      res = await ApiUtil.SendAsyncWithoutToken<GetClientIdResponse>({
        url: `/api/v2/customers/${customerId}/clients`,
        method: "GET",
      });
    }

    return res.data;
  }
}
