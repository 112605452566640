import {
  CallExtResponse,
  CalloutResponse,
  CampaignResponse,
  CreateCamapignRequest,
  CreateCampaignResponse,
  LocationResponse,
  CountriesResponse,
  CampaignResponseId,
  SitelinkResponse,
  AddExtensionResponse,
  LanguagesResponse,
  BiddingOptionResponse,
  GeoTargetResponse,
  CampaignCardResponse,
  CreterionResponse,
  LanguageByIdResponse,
  ChartReposnse,
  UpdateCampaignNameResponse,
  BiddingStrategyReposnse,
} from "../types/campaign";
import { AxiosResponse } from "axios";
import moment from "moment";
import ApiUtil from "../utils/apiUtil";

export default class CampaignService {
  async getCampaignData(data: {
    pageSize: number;
    nextPage: string;
    dates?: { sDate: Date; eDate: Date };
  }): Promise<CampaignResponse> {
    let url = `/api/campaigns?pageSize=${data.pageSize}`;
    if (data.nextPage) {
      url += `&nextPageToken=${data.nextPage}`;
    }
    if (data.dates) {
      url += `&startDate=${moment(data.dates.sDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(data.dates.eDate).format("YYYY-MM-DD")}`;
    }
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<CampaignResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async createCampaign(
    data: CreateCamapignRequest
  ): Promise<CreateCampaignResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsync<CreateCampaignResponse>({
      url: "/api/campaigns",
      method: "POST",
      data: data,
    });
    return res.data;
  }

  async getLocationData(): Promise<LocationResponse> {
    let url = `/api/locations`;
    const res: AxiosResponse = await ApiUtil.SendAsync<LocationResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async fetchLanguages(): Promise<LanguagesResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsync<LanguagesResponse>({
      url: "/api/languages",
      method: "GET",
    });
    return res.data;
  }

  async fetchCountries(): Promise<CountriesResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsync<CountriesResponse>({
      url: "/api/countries",
      method: "GET",
    });
    return res.data;
  }

  async fetchSitelinkData(): Promise<SitelinkResponse> {
    let url = `/api/asset/sitelink`;
    const res: AxiosResponse = await ApiUtil.SendAsync<SitelinkResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async fetchCalloutData(): Promise<CalloutResponse> {
    let url = `/api/asset/callout`;
    const res: AxiosResponse = await ApiUtil.SendAsync<CalloutResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }
  async fetchCallExtensionData(): Promise<CallExtResponse> {
    let url = `/api/asset/call`;
    const res: AxiosResponse = await ApiUtil.SendAsync<CallExtResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async getCampaign(id: number): Promise<CampaignResponseId> {
    let url = `/api/campaigns/${id}`;
    const res: AxiosResponse = await ApiUtil.SendAsync<CampaignResponseId>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async addExtenstionData(
    data: any,
    type: number
  ): Promise<AddExtensionResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsync<AddExtensionResponse>({
      url: "api/asset",
      method: "POST",
      data: data,
    });
    return res.data;
  }

  async fetchBiddingOptionData(): Promise<BiddingOptionResponse> {
    let url = `/api/bidding-options`;
    const res: AxiosResponse = await ApiUtil.SendAsync<BiddingOptionResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async getGeoTarget(value: string): Promise<GeoTargetResponse> {
    let url = `/api/geo-targets?name=${value}`;
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<GeoTargetResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async getCampaignCardData(): Promise<CampaignCardResponse> {
    let url = `/api/cards/campaign`;
    const res: AxiosResponse = await ApiUtil.SendAsync<CampaignCardResponse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async getCriterions(id: number): Promise<CreterionResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<CreterionResponse>({
      url: `/api/campaigns/${id}/criterions`,
      method: "GET",
    });
    return res.data;
  }

  async getLanguageId(id: string): Promise<LanguageByIdResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<LanguageByIdResponse>({
      url: `api/languages?ids=${id}`,
      method: "GET",
    });
    return res.data;
  }

  async getLocationId(id: string): Promise<GeoTargetResponse> {
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<GeoTargetResponse>({
      url: `api/geo-targets?ids=${id}`,
      method: "GET",
    });
    return res.data;
  }

  async loadChartData(data: {
    dates?: { sDate: Date; eDate: Date };
  }): Promise<ChartReposnse> {
    let url = `api/charts/campaign?`;
    if (data.dates) {
      url += `&startDate=${moment(data.dates.sDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(data.dates.eDate).format("YYYY-MM-DD")}`;
    }
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<ChartReposnse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async loadKeywordChartData(data: {
    dates?: { sDate: Date; eDate: Date };
  }): Promise<ChartReposnse> {
    let url = `api/charts/keyword?`;
    if (data.dates) {
      url += `&startDate=${moment(data.dates.sDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(data.dates.eDate).format("YYYY-MM-DD")}`;
    }
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<ChartReposnse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async loadAdsChartData(data: {
    dates?: { sDate: Date; eDate: Date };
  }): Promise<ChartReposnse> {
    let url = `api/charts/ad?`;
    if (data.dates) {
      url += `&startDate=${moment(data.dates.sDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(data.dates.eDate).format("YYYY-MM-DD")}`;
    }
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<ChartReposnse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }

  async updateCampaignData(data: any): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "PUT",
        data: data,
      }
    );
    return res.data;
  }

  async updateCampaignBudget(
    data: any,
    campaignId: string
  ): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns/${campaignId}/budgets`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "PUT",
        data: data,
      }
    );
    return res.data;
  }

  async updateCampaignLanguages(
    data: any,
    campaignId: string
  ): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns/${campaignId}/criterions`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "POST",
        data: data,
      }
    );
    return res.data;
  }

  async updateCampaignUrlOptions(
    data: any,
    campaignId: string
  ): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "PUT",
        data: data,
      }
    );
    return res.data;
  }

  async saveExtension(
    data: any,
    campaignId: string
  ): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns/${campaignId}/assets`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "POST",
        data: data,
      }
    );
    return res.data;
  }

  async updateLocationAction(
    data: any,
    campaignId: number
  ): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns/${campaignId}/criterions`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "POST",
        data: data,
      }
    );
    return res.data;
  }

  async deleteCampaign(data: any): Promise<UpdateCampaignNameResponse> {
    let url = `/api/campaigns`;
    const res: AxiosResponse = await ApiUtil.SendAsync<UpdateCampaignNameResponse>(
      {
        url: url,
        method: "DELETE",
        data: data,
      }
    );
    return res.data;
  }

  async fetchStrategy(): Promise<BiddingStrategyReposnse> {
    const res: AxiosResponse = await ApiUtil.SendAsync<BiddingStrategyReposnse>(
      {
        url: "/api/bidding-strategy",
        method: "GET",
      }
    );
    return res.data;
  }

  async loadSearchTermChartData(data: {
    dates?: { sDate: Date; eDate: Date };
  }): Promise<ChartReposnse> {
    let url = `api/charts/search-term?`;
    if (data.dates) {
      url += `&startDate=${moment(data.dates.sDate).format(
        "YYYY-MM-DD"
      )}&endDate=${moment(data.dates.eDate).format("YYYY-MM-DD")}`;
    }
    const res: AxiosResponse = await ApiUtil.SendAsyncV2<ChartReposnse>({
      url: url,
      method: "GET",
    });
    return res.data;
  }
}
