// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export const SET_DATE = "SET_DATE";
// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Campaign
export const GET_CAMPAIGN_DATA = "GET_CAMPAIGN_DATA";
export const GET_ALL_CAMPAIGN_DATA = "GET_ALL_CAMPAIGN_DATA";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_ALL_LOCATION_DATA = "GET_ALL_LOCATION_DATA";
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_CAMPAIGN_DATA_ID = "GET_CAMPAIGN_DATA_ID";
export const SET_CAMPAIGN_DATA_ID = "SET_CAMPAIGN_DATA_ID";
export const GET_CALLOUT_DATA_CAMPAIGN = "GET_CALLOUT_DATA_CAMPAIGN";
export const GET_GEO_TARGET = "GET_GEO_TARGET";
export const GET_CAMPAIGN_CARD_DATA = "GET_CAMPAIGN_CARD_DATA";
export const GET_GROUP_CARD_DATA = "GET_GROUP_CARD_DATA";
export const GET_KEYWORD_CARD_DATA = "GET_KEYWORD_CARD_DATA";
export const GET_AD_CARD_DATA = "GET_AD_CARD_DATA";
export const GET_CRITERION_DATA = "GET_CRITERION_DATA";
export const GET_LANGUAGE_BY_ID = "GET_LANGUAGE_BY_ID";
export const GET_LOCATION_BY_ID = "GET_LOCATION_BY_ID";
export const GET_CAMPAIGN_CHARTS = "GET_CAMPAIGN_CHARTS";
export const GET_GROUP_CHARTS = "GET_GROUP_CHARTS";
export const GET_KEYWORD_CHARTS = "GET_KEYWORD_CHARTS";
export const GET_ADS_CHARTS = "GET_ADS_CHARTS";
export const UPDATE_CAMPAIGN_NAME = "UPDATE_CAMPAIGN_NAME";
export const UPDATE_CAMPAIGN_NETWORK = "UPDATE_CAMPAIGN_NETWORK";
export const UPDATE_CAMPAIGN_BUDGET = "UPDATE_CAMPAIGN_BUDGET";
export const UPDATE_CAMPAIGN_LANGUAGES = "UPDATE_CAMPAIGN_LANGUAGES";
export const UPDATE_CAMPAIGN_URL_OPTIONS = "UPDATE_CAMPAIGN_URL_OPTIONS";
export const UPDATE_EXTENSION = "UPDATE_EXTENSION";
export const UPDATE_BIDDING = "UPDATE_BIDDING";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_GROUP_KEYWORDS = "UPDATE_GROUP_KEYWORDS";
export const GET_ALL_STRATEGY = "GET_ALL_STRATEGY";

// GET SITELINK LIST
export const GET_ALL_SITELINK_DATA = "GET_ALL_SITELINK_DATA";

// GET CALLOUT LIST
export const GET_CALLOUT_DATA = "GET_CALLOUT_DATA";

// GET CALL EXTENSION LIST
export const GET_CALLEXTENSION_DATA = "GET_CALLEXTENSION_DATA";

// Ad-Group
export const GET_ADGROUP_DATA = "GET_ADGROUP_DATA";
export const GET_SITELINK_DATA_CAMPAIGN = "GET_SITELINK_DATA_CAMPAIGN";
export const GET_WEBSITE_DATA_CAMPAIGN = "GET_WEBSITE_DATA_CAMPAIGN";
export const GET_WEBSITE_CAMPAIGN = "GET_WEBSITE_CAMPAIGN";
export const GET_ADGROUPADS_DATA = "GET_ADGROUPADS_DATA";
export const GET_AD_KEYWORD_DATA = "GET_AD_KEYWORD_DATA";
export const GET_EXTENSION_DATA = "GET_EXTENSION_DATA";
export const CREATE_AD_GROUP_SUCCESS = "CREATE_AD_GROUP_SUCCESS";
export const ADD_SITELINK_SUCCESS = "ADD_SITELINK_SUCCESS";

export const GET_SEARCH_TERM_DATA = "GET_SEARCH_TERM_DATA";
export const GET_SEARCH_TERM_CARD_DATA = "GET_SEARCH_TERM_CARD_DATA";
export const GET_SEARCH_TERM_CHARTS = "GET_SEARCH_TERM_CHARTS";

export const GET_BIDDING_STRATEGY_DATA = "GET_BIDDING_STRATEGY_DATA";
export const GET_BIDDING_STRATEGY_CARD_DATA = "GET_BIDDING_STRATEGY_CARD_DATA";
export const GET_BIDDING_STRATEGY_CHARTS = "GET_BIDDING_STRATEGY_CHARTS";

export const GET_BIDDINGOPTION_DATA = "GET_BIDDINGOPTION_DATA";
export const GET_GROUP_DATA_ID = "GET_GROUP_DATA_ID";
export const GET_KEYWORD_DATA_ID = "GET_KEYWORD_DATA_ID";
export const GET_ADS_DATA = "GET_ADS_DATA";
export const UPDATE_GROUP_NAME = "UPDATE_GROUP_NAME";
export const UPDATE_GROUP_AD_ROTATION = "UPDATE_GROUP_AD_ROTATION";
export const UPDATE_GROUP_ADS = "UPDATE_GROUP_ADS";
// Account
export const GET_USERLIST_DATA = "GET_USERLIST_DATA";
export const GET_MANAGERLIST_DATA = "GET_MANAGERLIST_DATA";
export const GET_CONVERSIONLIST_DATA = "GET_CONVERSIONLIST_DATA";
export const GET_CONVERSION_DATA_BY_ID = "GET_CONVERSION_DATA_BY_ID";
export const CREATE_CONVERSION_SUCCESS = "CREATE_CONVERSION_SUCCESS";
export const GET_CLIENT_ID = "GET_CLIENT_ID";
