import * as React from "react";
import { Redirect } from "react-router-dom";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Redirect to="/sign-in" />;
  }

  return <>{children}</>;
}

export default AuthGuard;
