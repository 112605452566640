export const primarycolor = "#376fd0";
export const secondarycolor = "#ff1f78";
export const secondaryhover = "#e00b60";

export const white = "#ffffff";
export const black = "#333";
export const blackdark = "#000";
export const gray = "#757575";
export const graydark = "#7c7b7c";
export const border = "#d3d3d4";

export const graybutton = "#f2f2f2";
export const graybuttonhover = "#2f2f2f";

export const green = "#00a900";
export const red = "#FF0000";
export const blue = "#2064ee";
