import * as types from "../../constants";
import { AppDispatchType } from "../store";
import {
  AddExtensionResponse,
  BiddingOptionResponse,
  BiddingStrategyReposnse,
  CallExtResponse,
  CalloutResponse,
  CampaignCardResponse,
  CampaignResponse,
  CampaignResponseId,
  Campaigns,
  ChartReposnse,
  CountriesResponse,
  CreateCamapignRequest,
  CreterionResponse,
  GeoTargetResponse,
  LanguageByIdResponse,
  LanguagesResponse,
  LocationResponse,
  SitelinkResponse,
} from "../../types/campaign";

import CampaignService from "../../services/campaignService";

const service = new CampaignService();

export function getCamapign(data: {
  pageSize: number;
  nextPage: string;
  dates: { sDate: Date; eDate: Date };
}) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CampaignResponse = await service.getCampaignData(data);
      if (response.results) {
        dispatch({
          type: types.GET_CAMPAIGN_DATA,
          campaigns: response,
        });
      }
    } catch (error) {
      throw error;
    }
  };
}

export function getAllCampaign(data: { pageSize: number; nextPage: string }) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CampaignResponse = await service.getCampaignData(data);
      dispatch({
        type: types.GET_ALL_CAMPAIGN_DATA,
        campaigns: response,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function createCampaign(data: CreateCamapignRequest) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.createCampaign(data);
      dispatch({
        type: types.CREATE_CAMPAIGN_SUCCESS,
        create: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.CREATE_CAMPAIGN_SUCCESS,
          create: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.CREATE_CAMPAIGN_SUCCESS,
        create: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function getLanguages() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: LanguagesResponse = await service.fetchLanguages();
      dispatch({
        type: types.GET_LANGUAGES_SUCCESS,
        languages: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getAllLocation() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: LocationResponse = await service.getLocationData();
      dispatch({
        type: types.GET_ALL_LOCATION_DATA,
        locations: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getAllCountries() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CountriesResponse = await service.fetchCountries();
      dispatch({
        type: types.GET_ALL_COUNTRIES,
        countries: response.data ? response.data : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getSiteLinkData() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: SitelinkResponse = await service.fetchSitelinkData();
      if (response.results)
        dispatch({
          type: types.GET_ALL_SITELINK_DATA,
          allSitelink: response.results,
        });
    } catch (error) {
      throw error;
    }
  };
}

export function getCallOutData() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CalloutResponse = await service.fetchCalloutData();
      if (response.results)
        dispatch({
          type: types.GET_CALLOUT_DATA,
          allCallout: response.results,
        });
    } catch (error) {
      throw error;
    }
  };
}

export function getCallExtensionData() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CallExtResponse = await service.fetchCallExtensionData();
      if (response.results)
        dispatch({
          type: types.GET_CALLEXTENSION_DATA,
          allCallExt: response.results,
        });
    } catch (error) {
      throw error;
    }
  };
}

export function getCampaign(id: number) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CampaignResponseId = await service.getCampaign(id);
      if (response.results && response.results.length > 0) {
        dispatch({
          type: types.GET_CAMPAIGN_DATA_ID,
          campaign: response.results[0],
        });
      }
    } catch (error) {
      throw error;
    }
  };
}

export function refreshCampaignData() {
  return async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: types.SET_CAMPAIGN_DATA_ID,
        campaign: {},
      });
      dispatch({
        type: types.GET_LANGUAGE_BY_ID,
        langaugesId: [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function addExtension(data: any, type: number) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: AddExtensionResponse = await service.addExtenstionData(
        data,
        type
      );
      dispatch({
        type: types.ADD_SITELINK_SUCCESS,
        create: response,
      });
      setTimeout(() => {
        dispatch({
          type: types.ADD_SITELINK_SUCCESS,
          create: { message: "" },
        });
      }, 5000);
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export function getBiddingOptionData() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: BiddingOptionResponse = await service.fetchBiddingOptionData();
      dispatch({
        type: types.GET_BIDDINGOPTION_DATA,
        allBiddingData: response.data.campaignBiddingOptions,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getGeoTarget(value: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: GeoTargetResponse = await service.getGeoTarget(value);
      if (response.results)
        dispatch({
          type: types.GET_GEO_TARGET,
          geoTargets: response.results,
        });
    } catch (error) {
      throw error;
    }
  };
}

export function resetGeoTarget() {
  return async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: types.GET_GEO_TARGET,
        geoTargets: [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getCamapignCards() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CampaignCardResponse = await service.getCampaignCardData();
      dispatch({
        type: types.GET_CAMPAIGN_CARD_DATA,
        campaignsCard: response,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getCriterions(id: number) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: CreterionResponse = await service.getCriterions(id);
      dispatch({
        type: types.GET_CRITERION_DATA,
        criterion: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function getLanguageId(id: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: LanguageByIdResponse = await service.getLanguageId(id);
      if (response.results)
        dispatch({
          type: types.GET_LANGUAGE_BY_ID,
          langaugesId: response.results,
        });
    } catch (error) {
      throw error;
    }
  };
}

export function getLocationId(id: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: GeoTargetResponse = await service.getLocationId(id);
      if (response.results)
        dispatch({
          type: types.GET_LOCATION_BY_ID,
          locationId: response.results,
        });
    } catch (error) {
      throw error;
    }
  };
}

export function loadChartData(data: { dates: { sDate: Date; eDate: Date } }) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: ChartReposnse = await service.loadChartData(data);
      dispatch({
        type: types.GET_CAMPAIGN_CHARTS,
        campaignCharts: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function loadKeywordChartData(data: {
  dates: { sDate: Date; eDate: Date };
}) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: ChartReposnse = await service.loadKeywordChartData(data);
      dispatch({
        type: types.GET_KEYWORD_CHARTS,
        keywordCharts: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function loadAdsChartData(data: {
  dates: { sDate: Date; eDate: Date };
}) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: ChartReposnse = await service.loadAdsChartData(data);
      dispatch({
        type: types.GET_ADS_CHARTS,
        AdsCharts: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function updateCamapign(data: any) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateCampaignData(data);
      dispatch({
        type: types.UPDATE_CAMPAIGN_NAME,
        updatename: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_NAME,
          updatename: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_CAMPAIGN_NAME,
        updatename: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function updateCamapignNetwork(data: any) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateCampaignData(data);
      dispatch({
        type: types.UPDATE_CAMPAIGN_NETWORK,
        updatenetwork: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_NETWORK,
          updatenetwork: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_CAMPAIGN_NETWORK,
        updatenetwork: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function updateCamapignBudget(data: any, campaignId: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateCampaignBudget(data, campaignId);
      dispatch({
        type: types.UPDATE_CAMPAIGN_BUDGET,
        updatebudget: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_BUDGET,
          updatebudget: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_CAMPAIGN_BUDGET,
        updatebudget: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function updateCampaignLanguages(data: any, campaignId: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateCampaignLanguages(data, campaignId);
      dispatch({
        type: types.UPDATE_CAMPAIGN_LANGUAGES,
        updateLanguages: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_LANGUAGES,
          updateLanguages: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_CAMPAIGN_LANGUAGES,
        updateLanguages: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function updateLanguageList(data: any) {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.GET_LANGUAGE_BY_ID,
      langaugesId: data,
    });
  };
}

export function updateCampaignUrlOptions(data: any, campaignId: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateCampaignUrlOptions(data, campaignId);
      dispatch({
        type: types.UPDATE_CAMPAIGN_URL_OPTIONS,
        updateUrl: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_URL_OPTIONS,
          updateUrl: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_CAMPAIGN_URL_OPTIONS,
        updateUrl: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function saveExtension(data: any, campaignId: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.saveExtension(data, campaignId);
      dispatch({
        type: types.UPDATE_EXTENSION,
        updateExtension: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_EXTENSION,
          updateExtension: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_EXTENSION,
        updateExtension: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function setCampaign(data: any) {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.GET_CAMPAIGN_DATA_ID,
      campaign: data,
    });
  };
}

export function saveBiddingAction(data: any) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateCampaignData(data);
      dispatch({
        type: types.UPDATE_BIDDING,
        updateBidding: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_BIDDING,
          updateBidding: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_BIDDING,
        updateBidding: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function setCampaignTable(data: CampaignResponse) {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.GET_CAMPAIGN_DATA,
      campaigns: data,
    });
  };
}

export function setAllCampaign(data: Campaigns[]) {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.GET_ALL_CAMPAIGN_DATA,
      campaigns: { results: data },
    });
  };
}

export function deleteCampaign(data: any) {
  return async () => {
    try {
      await service.deleteCampaign(data);
    } catch (error) {
      throw error;
    }
  };
}

export function updateLocationAction(
  data: any,
  location: any,
  campaignId: number
) {
  return async (dispatch: AppDispatchType) => {
    try {
      await service.updateLocationAction(data, campaignId);
      await service.updateCampaignData(location);
      dispatch({
        type: types.UPDATE_LOCATION,
        updateLocation: { message: "SUCCESS" },
      });
      setTimeout(() => {
        dispatch({
          type: types.UPDATE_LOCATION,
          updateLocation: { message: "" },
        });
      }, 5000);
    } catch (error) {
      dispatch({
        type: types.UPDATE_LOCATION,
        updateLocation: { message: "ERROR" },
      });
      throw error;
    }
  };
}

export function getStrategyPortfolio() {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: BiddingStrategyReposnse = await service.fetchStrategy();
      dispatch({
        type: types.GET_ALL_STRATEGY,
        biddingStrategies: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}

export function loadSearchTermChartData(data: {
  dates: { sDate: Date; eDate: Date };
}) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: ChartReposnse = await service.loadSearchTermChartData(
        data
      );
      dispatch({
        type: types.GET_SEARCH_TERM_CHARTS,
        searchTermCharts: response.results ? response.results : [],
      });
    } catch (error) {
      throw error;
    }
  };
}
