import styled from "styled-components/macro";

import { white } from "../../assets/variables/variables.style";

//Import Material
import {
  Chip,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Divider as MuiDivider,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { rgba } from "polished";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import { spacing } from "@material-ui/system";
import { NavLink } from "react-router-dom";

export const Divider = styled(MuiDivider)(spacing);

export const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
    background: none;
  }

  .sidebarHeader {
    background: ${white};
    .title {
      margin-left: 0;
      img {
        width: 150px;
      }
    }
  }
`;

export const Scrollbar = styled(PerfectScrollbar)<{
  menutype: number;
}>`
  background-color: ${(props) =>
    props.menutype === 1 ? props.theme.sidebar.background : "#f7f7f7"};
  border-right: 1.5px solid #00000054;
  border-top-right-radius: 35px;
  padding-top: 20px;
`;

export const List = styled(MuiList)<{
  menutype: number;
}>`
  background-color: ${(props) =>
    props.menutype === 1 ? props.theme.sidebar.background : "#f7f7f7"};
`;

export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
  menutype: number;
};

export const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  padding-left: ${(props) => props.theme.spacing(4)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  align-items: self-start;

  svg {
    color: ${(props) => (props.menutype === 1 ? "#fff" : "#000")};
    font-size: 20px;
    width: 18px;
    height: 18px;
    opacity: 1;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    span {
      color: #ff1f78;
    }
  }

  &.removed {
    background: #484747;
    span {
      color: #ababab;
    }
    svg {
      color: #ababab;
    }
  }
`;

export const CategoryText = styled(ListItemText)<{
  menutype: number;
}>`
  margin: 0;
  span {
    color: ${(props) =>
      props.menutype === 1 ? props.theme.sidebar.color : "#000"};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding-left: 10px;
    word-break: break-word;
    line-height: 16px;
  }
`;

export const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to: string;
}>`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

export const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

export const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

export const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

export const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

export const SidebarFooter = styled.div`
  background-color: #2f2e30;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  cursor: pointer;
  .active {
    color: #ff1f78;
  }
  .removed {
    background: #484747;
    span {
      color: #ababab;
    }
    svg {
      color: #ababab;
    }
  }
`;

export const SidebarFooterText = styled(Typography)`
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  svg {
    margin-right: 5px;
    position: relative;
    top: -1px;
  }
`;
