import * as types from "../../constants";
import {
  Campaigns,
  Countries,
  Locations,
  CallExtensions,
  Callout,
  Languages,
  GeoTargetConstant,
  CampaignCardResponse,
  CampaignId,
  Creterion,
  LanguageId,
  Charts,
  BiddingStrategy,
  Asset,
} from "../../types/campaign";

export type campainType = {
  campaigns: {
    results: Campaigns[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  campaignsCard: CampaignCardResponse;
  locations: Locations[];
  allCampaigns: Campaigns[];
  allLocation: Locations[];
  allSitelink: Asset[];
  allCallout: Callout[];
  allCallExt: CallExtensions[];
  allBiddingData: string[];
  languages: Languages[];
  create: {
    message: string;
  };
  countries: Countries[];
  campaign: CampaignId;
  geoTargets: GeoTargetConstant[];
  criterion: Creterion[];
  langaugesId: LanguageId[];
  locationId: GeoTargetConstant[];
  campaignCharts: Charts[];
  keywordCharts: Charts[];
  searchTermCharts: Charts[];
  biddingStrategyCharts: Charts[];
  AdsCharts: Charts[];
  updatename: {
    message: string;
  };
  updatenetwork: {
    message: string;
  };
  updatebudget: {
    message: string;
  };
  updateLanguages: {
    message: string;
  };
  updateUrl: {
    message: string;
  };
  updateExtension: {
    message: string;
  };
  updateBidding: {
    message: string;
  };
  updateLocation: {
    message: string;
  };
  biddingStrategies: BiddingStrategy[];
};

const initialState: campainType = {
  campaigns: {
    results: [],
    nextPageToken: "",
    totalResultsCount: "0",
  },
  campaignsCard: {
    clicks: 0,
    conversions: 0,
    impressions: 0,
    ctr: 0,
    conversionsFromInteractionsRate: 0,
  },
  locations: [],
  allCampaigns: [],
  allLocation: [],
  allSitelink: [],
  allCallout: [],
  allCallExt: [],
  allBiddingData: [],
  languages: [],
  create: {
    message: "",
  },
  countries: [],
  campaign: {
    campaign: {
      urlCustomParameters: [],
      networkSettings: {
        targetSearchNetwork: false,
        targetContentNetwork: false,
      },
      biddingStrategyType: "",
      manualCpc: {
        enhancedCpcEnabled: false,
      },
      geoTargetTypeSetting: {
        positiveGeoTargetType: "PRESENCE_OR_INTEREST",
        negativeGeoTargetType: "PRESENCE_OR_INTEREST",
      },
      name: "",
      id: "0",
      trackingUrlTemplate: "",
      finalUrlSuffix: "",
    },
    campaignBudget: {
      id: "",
      amountMicros: 0,
      resourceName: "",
    },
  },
  geoTargets: [],
  criterion: [],
  langaugesId: [],
  locationId: [],
  campaignCharts: [],
  keywordCharts: [],
  searchTermCharts: [],
  biddingStrategyCharts: [],
  AdsCharts: [],
  updatename: {
    message: "",
  },
  updatenetwork: {
    message: "",
  },
  updatebudget: {
    message: "",
  },
  updateLanguages: {
    message: "",
  },
  updateUrl: {
    message: "",
  },
  updateExtension: {
    message: "",
  },
  updateBidding: {
    message: "",
  },
  updateLocation: {
    message: "",
  },
  biddingStrategies: [],
};

export default function reducer(
  state = initialState,
  actions: campainType & { type: string }
): campainType {
  switch (actions.type) {
    case types.GET_CAMPAIGN_DATA:
      return {
        ...state,
        campaigns: actions.campaigns,
      };
    case types.GET_ALL_CAMPAIGN_DATA:
      return {
        ...state,
        allCampaigns: actions.campaigns.results,
      };
    case types.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        create: actions.create,
      };
    case types.GET_ALL_LOCATION_DATA:
      return {
        ...state,
        allLocation: actions.locations,
      };
    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: actions.languages,
      };
    case types.GET_ALL_COUNTRIES:
      return {
        ...state,
        countries: actions.countries,
      };
    case types.GET_ALL_SITELINK_DATA:
      return {
        ...state,
        allSitelink: actions.allSitelink,
      };
    case types.GET_CALLOUT_DATA:
      return {
        ...state,
        allCallout: actions.allCallout,
      };
    case types.GET_CALLEXTENSION_DATA:
      return {
        ...state,
        allCallExt: actions.allCallExt,
      };
    case types.GET_CAMPAIGN_DATA_ID:
      return {
        ...state,
        campaign: actions.campaign,
      };
    case types.SET_CAMPAIGN_DATA_ID:
      return {
        ...state,
        campaign: initialState.campaign,
      };
    case types.GET_CAMPAIGN_CARD_DATA:
      return {
        ...state,
        campaignsCard: actions.campaignsCard,
      };
    case types.GET_GEO_TARGET:
      return {
        ...state,
        geoTargets: actions.geoTargets,
      };
    case types.GET_BIDDINGOPTION_DATA:
      return {
        ...state,
        allBiddingData: actions.allBiddingData,
      };
    case types.GET_CRITERION_DATA:
      return {
        ...state,
        criterion: actions.criterion,
      };
    case types.GET_LANGUAGE_BY_ID:
      return {
        ...state,
        langaugesId: actions.langaugesId,
      };
    case types.GET_LOCATION_BY_ID:
      return {
        ...state,
        locationId: actions.locationId,
      };
    case types.GET_CAMPAIGN_CHARTS:
      return {
        ...state,
        campaignCharts: actions.campaignCharts,
      };
    case types.GET_KEYWORD_CHARTS:
      return {
        ...state,
        keywordCharts: actions.keywordCharts,
      };
    case types.GET_ADS_CHARTS:
      return {
        ...state,
        AdsCharts: actions.AdsCharts,
      };
    case types.UPDATE_CAMPAIGN_NAME:
      return {
        ...state,
        updatename: actions.updatename,
      };
    case types.UPDATE_CAMPAIGN_NETWORK:
      return {
        ...state,
        updatenetwork: actions.updatenetwork,
      };
    case types.UPDATE_CAMPAIGN_BUDGET:
      return {
        ...state,
        updatebudget: actions.updatebudget,
      };
    case types.UPDATE_CAMPAIGN_LANGUAGES:
      return {
        ...state,
        updateLanguages: actions.updateLanguages,
      };
    case types.UPDATE_CAMPAIGN_URL_OPTIONS:
      return {
        ...state,
        updateUrl: actions.updateUrl,
      };
    case types.UPDATE_EXTENSION:
      return {
        ...state,
        updateExtension: actions.updateExtension,
      };
    case types.UPDATE_BIDDING:
      return {
        ...state,
        updateBidding: actions.updateBidding,
      };
    case types.UPDATE_LOCATION:
      return {
        ...state,
        updateLocation: actions.updateLocation,
      };
    case types.GET_ALL_STRATEGY:
      return {
        ...state,
        biddingStrategies: actions.biddingStrategies,
      };
    case types.GET_SEARCH_TERM_CHARTS:
      return {
        ...state,
        searchTermCharts: actions.searchTermCharts,
      };
    case types.GET_BIDDING_STRATEGY_CHARTS:
      return {
        ...state,
        biddingStrategyCharts: actions.biddingStrategyCharts,
      };
    default:
      return state;
  }
}
