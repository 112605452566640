import styled from "styled-components/macro";

export const DatePickerWrapper = styled.div`
display: inline-flex;
flex-wrap: wrap;
margin-left: 11px;
.dateInnerBox {
  position: relative;
  .chooseBox {    
    display: flex;
    align-items: center;
    p {
      font-size: 0.7125rem;
      margin: 0;
    }
    input {
      border-radius: 20px;
      height: 28px;
      border 1px solid ${(props) => props.theme.palette.grey[300]};
      padding: 0px 5px;
      margin-left: 5px;
      font-size: 0.7125rem;
      text-align: center;
      &:focus {
        outline: none
      }
   } 
  }
  .buttonBox {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: -1px 0 10px -1px #7e7e7e;
  border-radius: 5px;
  width: 485px;
  @media (max-width:767px) {
    width: 240px;
  }
  
}
.hoursContent {
  margin: 0;
  text-align: right;
  padding-right: 5px;
  b {
    color: #fff;
    background-color: #4782da;
    font-size: 10px;
    border-radius: 5px;
    padding: 4px 10px;
    font-weight: normal;
  }
}
  .bottomBotton {
    padding: 10px 15px 15px;
    button {
      @media (max-width:767px) {
        margin-bottom:8px;
      }
     }
  }
}
`;
