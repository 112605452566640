import "react-toastify/dist/ReactToastify.css";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";

import { refreshTokenSetup } from "./refreshToken";

export const GoogleSignIn = (onSuccessFun: () => void) => {
  const signIn = useGoogleLogin({
    flow: "implicit",
    scope: "https://www.googleapis.com/auth/adwords",
    prompt: "consent",
    onSuccess: (res) => {
      const date = new Date();
      const second = date.getSeconds() + res.expires_in;
      const expriTime = date.setSeconds(second);
      localStorage.setItem(
        "token",
        JSON.stringify({
          token: res.access_token,
          exp: res.expires_in,
          expirationTime: expriTime,
        })
      );
      getProfile(res);
    },
  });

  async function getProfile(res: any) {
    try {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${res.access_token}`
      );
      const responseJson = await response.json();
      if (!response.ok) {
        throw new Error(JSON.stringify(responseJson));
      } else {
        localStorage.setItem(
          "profile",
          JSON.stringify({
            img: responseJson.name,
            img_url: responseJson.picture,
            email: responseJson.email,
          })
        );
        refreshTokenSetup(res);
        onSuccessFun();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const signOut = googleLogout();

  return {
    signIn,
    signOut,
  };
};
