import * as React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import { withTheme } from "styled-components/macro";

import { Grid, Hidden, Toolbar, Avatar } from "@material-ui/core";
import Link from "@mui/material/Link";

import { Menu as MenuIcon } from "@material-ui/icons";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import UserDropdown from "./UserDropdown";
import DatePickerCard from "./DatePicker/DatePicker";
import StorageUtil from "../utils/storageUtil";
import {
  AppBar,
  IconButton,
  HeaderUserContainer,
  HeaderUserTextTitle,
  HeaderUserText,
  Brand,
  Box,
  HeaderText,
} from "./AppBar.style";

import Logo from "../assets/logo.jpg";
import { setDate } from "../redux/actions/themeActions";

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const profile = StorageUtil.getLocalStorage("profile");
  const dateChange = (date: { sDate: Date; eDate: Date }) => {
    dispatch(setDate(date));
  };
  let eDate = new Date();
  eDate.setDate(eDate.getDate() - 15);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" className="headerWrapper">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item className="headerleft">
              <Brand
                className="sidebarHeader"
                component={NavLink}
                to="/"
                button
              >
                <Box className="contentBox">
                  <img src={Logo} alt="Trendata" />
                  <HeaderText>Google Search Ads</HeaderText>
                </Box>
              </Brand>
            </Grid>
            <Grid item className="headerRight">
              <Box className="googleAds">
                <Link href="https://ads.google.com/intl/en_in/home/">
                  <ManageSearchIcon /> Google Ads
                </Link>
              </Box>
              <DatePickerCard DateChange={dateChange} />
              <HeaderUserContainer container>
                <Grid item>
                  <HeaderUserTextTitle variant="body2">
                    {StorageUtil.getLocalStorage("customerId")}
                  </HeaderUserTextTitle>
                  <HeaderUserText variant="caption">
                    {profile.img ? profile.img : ""} -
                    {profile.email ? profile.email : ""}
                  </HeaderUserText>
                </Grid>
                <Grid item>
                  <Avatar
                    className="userIcon"
                    alt={profile.img}
                    src={profile.img_url}
                  />
                </Grid>
              </HeaderUserContainer>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
