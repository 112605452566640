import * as types from "../../constants";
import { AppDispatchType } from "../store";
import {
  SignInType,
  SignUpType,
  ResetPasswordType,
  GetClientIdResponse,
} from "../../types/auth";
import AuthService from "../../services/authService";
import { toast } from "react-toastify";

const service = new AuthService();

export function signIn(credentials: SignInType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return true;
  };
}

export function signUp(credentials: SignUpType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return true;
  };
}

export function signOut() {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(credentials: ResetPasswordType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return true;
  };
}

export function getClientId(customerId: string, managerId: string) {
  return async (dispatch: AppDispatchType) => {
    try {
      const response: GetClientIdResponse = await service.getClientId(
        customerId,
        managerId
      );
      if (response)
        dispatch({
          type: types.GET_CLIENT_ID,
          clientId: response.clientId,
        });
    } catch (error) {
      toast.error("Please enter correct customer id");
      throw error;
    }
  };
}

export function resetClientId() {
  return async (dispatch: AppDispatchType) => {
    dispatch({
      type: types.GET_CLIENT_ID,
      clientId: "",
    });
  };
}
