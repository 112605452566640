import axios, { AxiosResponse } from "axios";
import { RequestObject } from "../types/axios";
import StorageUtil from "./storageUtil";
export default class ApiUtil {
  private constructor() {}

  /**
   * To make generic  api call ie. post get etc.
   * @param requestObject
   */

  static async SendAsync<T>(
    requestObject: RequestObject
  ): Promise<AxiosResponse<T>> {
    const token = StorageUtil.getLocalStorage("token");
    axios.defaults.headers.common[
      "x-customer-id"
    ] = StorageUtil.getLocalStorage("customerId");
    axios.defaults.headers.common["x-manager-id"] = StorageUtil.getLocalStorage(
      "managerId"
    );
    axios.defaults.headers.common["authorization"] = token.token;
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    try {
      const res = await axios(requestObject);
      if (res.status < 200 && res.status > 300) {
        throw console.error(`Error-${res.status}: ${res.statusText}`);
      }
      return res;
    } catch (error) {
      throw error;
    }
  }

  static async SendAsyncV2<T>(
    requestObject: RequestObject
  ): Promise<AxiosResponse<T>> {
    const token = StorageUtil.getLocalStorage("token");

    // const resp = await this.CallFunM();
    // reload();
    // resp();

    axios.defaults.headers.common[
      "x-customer-id"
    ] = StorageUtil.getLocalStorage("customerId");
    axios.defaults.headers.common["x-manager-id"] = StorageUtil.getLocalStorage(
      "managerId"
    );
    axios.defaults.headers.common["authorization"] = token.token;
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

    try {
      const res = await axios(requestObject);
      if (res.status < 200 && res.status > 300) {
        throw console.error(`Error-${res.status}: ${res.statusText}`);
      }
      return res;
    } catch (error) {
      // if (
      //   (error && error.response && error.response.status === 401) ||
      //   error.response.data.message.toLowerCase() ===
      //     "You are not authorize to perfrom access"
      // ) {
      //   localStorage.clear();
      //   window.location.href = "/login";
      // }
      throw error;
    }
  }

  static async SendAsyncWithoutToken<T>(
    requestObject: RequestObject
  ): Promise<AxiosResponse<T>> {
    axios.defaults.headers.common[
      "authorization"
    ] = `${process.env.REACT_APP_CLIENT_API_BASIC_TOKEN}`;
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
    try {
      const res = await axios(requestObject);
      if (res.status < 200 && res.status > 300) {
        throw console.error(`Error-${res.status}: ${res.statusText}`);
      }
      return res;
    } catch (error) {
      throw error;
    }
  }
}
