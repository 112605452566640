import StorageUtil from "./storageUtil";

const NINE_HUNDRED_SECONDS = 900;
const ONE_THOUSAND_MILISECONDS = 1000;

const getMiliSecondsFromSeconds = (seconds: number) => {
  return (seconds - NINE_HUNDRED_SECONDS) * ONE_THOUSAND_MILISECONDS;
};

const refreshToken = async (res: any) => {
  const newAuthRes = await res.reloadAuthResponse();
  const refreshTime = getMiliSecondsFromSeconds(newAuthRes.expires_in);
  const token = {
    token: newAuthRes.access_token,
    exp: newAuthRes.expires_at,
  };
  StorageUtil.setLocalStorage("token", token);
  setTimeout(() => refreshToken(res), refreshTime);
};

export const refreshTokenSetup = (res: any) => {
  // Init refresh token first time.
  const refreshTiming = getMiliSecondsFromSeconds(res.expires_in);
  setTimeout(() => refreshToken(res), refreshTiming);
};
