import * as types from "../../constants";
import {
  AdCardResponse,
  AdGroupAdsObject,
  AdGroupObject,
  AdKeywordObject,
  AdsData,
  BiddingStrategyObject,
  Callout,
  Charts,
  GroupCardResponse,
  GroupId,
  KeywordCardResponse,
  KeywordId,
  SearchTermObject,
  websiteInfoExtensionFeedItems,
} from "../../types/adGroup";
import { ExtensionCampaign } from "../../types/adGroup";

export type adGroupType = {
  adKeyword: {
    results: AdKeywordObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  adGroups: {
    results: AdGroupObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  groupCard: GroupCardResponse;
  keywordCard: KeywordCardResponse;
  adCard: AdCardResponse;
  adGroupsAds: AdGroupAdsObject[];
  allCallout: Callout[];
  campaignCallout: string[];
  siteLinks: string[];
  websiteData: string[];
  websites: websiteInfoExtensionFeedItems[];
  extensions: ExtensionCampaign[];
  groupCharts: Charts[];
  create: {
    message: string;
  };
  group: GroupId;
  keyword: KeywordId[];
  adGroupAd: AdsData[];
  updatename: {
    message: string;
  };
  updateadrotation: {
    message: string;
  };
  updateads: {
    message: string;
  };
  updateKeywords: {
    message: string;
  };
  searchTerm: {
    results: SearchTermObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  searchTermCard: KeywordCardResponse;
  biddingStrategyCard: KeywordCardResponse;
  biddingStrategy: {
    results: BiddingStrategyObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
};

export type AuthType = {
  adGroups: {
    results: AdGroupObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  adGroupsAds: AdGroupAdsObject[];
  groupCard: GroupCardResponse;
  keywordCard: KeywordCardResponse;
  adCard: AdCardResponse;
  adKeyword: {
    results: AdKeywordObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  allCallout: Callout[];
  campaignCallout: string[];
  siteLinks: string[];
  websiteData: string[];
  websites: websiteInfoExtensionFeedItems[];
  extensions: ExtensionCampaign[];
  create: {
    message: string;
  };
  groupCharts: Charts[];
  group: GroupId;
  keyword: KeywordId[];
  adGroupAd: AdsData[];
  updatename: {
    message: string;
  };
  updateadrotation: {
    message: string;
  };
  updateads: {
    message: string;
  };
  updateKeywords: {
    message: string;
  };
  searchTerm: {
    results: SearchTermObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
  searchTermCard: KeywordCardResponse;
  biddingStrategyCard: KeywordCardResponse;
  biddingStrategy: {
    results: BiddingStrategyObject[];
    nextPageToken: string;
    totalResultsCount: string;
  };
};

const initialState: adGroupType = {
  adGroups: {
    results: [],
    nextPageToken: "",
    totalResultsCount: "0",
  },
  adGroupsAds: [],
  adKeyword: {
    results: [],
    nextPageToken: "",
    totalResultsCount: "0",
  },
  searchTerm: {
    results: [],
    nextPageToken: "",
    totalResultsCount: "",
  },
  biddingStrategy: {
    results: [],
    nextPageToken: "",
    totalResultsCount: "",
  },
  allCallout: [],
  campaignCallout: [],
  siteLinks: [],
  websiteData: [],
  websites: [],
  groupCard: {
    clicks: 0,
    conversions: 0,
    impressions: 0,
    ctr: 0,
    conversionsFromInteractionsRate: 0,
  },
  keywordCard: {
    clicks: 0,
    conversions: 0,
    impressions: 0,
    ctr: 0,
    conversionsFromInteractionsRate: 0,
  },
  adCard: {
    clicks: 0,
    conversions: 0,
    impressions: 0,
    ctr: 0,
    conversionsFromInteractionsRate: 0,
  },
  extensions: [],
  create: {
    message: "",
  },
  groupCharts: [],
  group: {
    adGroup: {
      resourceName: "",
      adRotationMode: "",
      id: "",
      name: "",
    },
  },
  keyword: [],
  adGroupAd: [],
  updatename: {
    message: "",
  },
  updateadrotation: {
    message: "",
  },
  updateads: {
    message: "",
  },
  updateKeywords: {
    message: "",
  },
  searchTermCard: {
    clicks: 0,
    conversions: 0,
    impressions: 0,
    ctr: 0,
    conversionsFromInteractionsRate: 0,
  },
  biddingStrategyCard: {
    clicks: 0,
    conversions: 0,
    impressions: 0,
    ctr: 0,
    conversionsFromInteractionsRate: 0,
  },
};

export default function reducer(
  state = initialState,
  actions: AuthType & { type: string }
): adGroupType {
  switch (actions.type) {
    case types.GET_ADGROUP_DATA:
      return {
        ...state,
        adGroups: actions.adGroups,
      };
    case types.GET_CALLOUT_DATA:
      return {
        ...state,
        allCallout: actions.allCallout,
      };
    case types.GET_CALLOUT_DATA_CAMPAIGN:
      return {
        ...state,
        campaignCallout: actions.campaignCallout,
      };
    case types.GET_SITELINK_DATA_CAMPAIGN:
      return {
        ...state,
        siteLinks: actions.siteLinks,
      };
    case types.GET_WEBSITE_DATA_CAMPAIGN:
      return {
        ...state,
        websiteData: actions.websiteData,
      };
    case types.GET_WEBSITE_CAMPAIGN:
      return {
        ...state,
        websites: actions.websites,
      };
    case types.GET_ADGROUPADS_DATA:
      return {
        ...state,
        adGroupsAds: actions.adGroupsAds,
      };
    case types.GET_AD_KEYWORD_DATA:
      return {
        ...state,
        adKeyword: actions.adKeyword,
      };
    case types.GET_GROUP_CARD_DATA:
      return {
        ...state,
        groupCard: actions.groupCard,
      };
    case types.GET_KEYWORD_CARD_DATA:
      return {
        ...state,
        keywordCard: actions.keywordCard,
      };
    case types.GET_AD_CARD_DATA:
      return {
        ...state,
        adCard: actions.adCard,
      };
    case types.GET_EXTENSION_DATA:
      return {
        ...state,
        extensions: actions.extensions,
      };
    case types.CREATE_AD_GROUP_SUCCESS:
      return {
        ...state,
        create: actions.create,
      };
    case types.GET_GROUP_CHARTS:
      return {
        ...state,
        groupCharts: actions.groupCharts,
      };
    case types.GET_GROUP_DATA_ID:
      return {
        ...state,
        group: actions.group,
      };
    case types.GET_KEYWORD_DATA_ID:
      return {
        ...state,
        keyword: actions.keyword,
      };
    case types.GET_ADS_DATA:
      return {
        ...state,
        adGroupAd: actions.adGroupAd,
      };
    case types.UPDATE_GROUP_NAME:
      return {
        ...state,
        updatename: actions.updatename,
      };
    case types.UPDATE_GROUP_AD_ROTATION:
      return {
        ...state,
        updateadrotation: actions.updateadrotation,
      };
    case types.UPDATE_GROUP_ADS:
      return {
        ...state,
        updateads: actions.updateads,
      };
    case types.UPDATE_GROUP_KEYWORDS:
      return {
        ...state,
        updateKeywords: actions.updateKeywords,
      };
    case types.GET_SEARCH_TERM_DATA:
      return {
        ...state,
        searchTerm: actions.searchTerm,
      };
    case types.GET_SEARCH_TERM_CARD_DATA:
      return {
        ...state,
        searchTermCard: actions.searchTermCard,
      };
    case types.GET_BIDDING_STRATEGY_DATA:
      return {
        ...state,
        biddingStrategy: actions.biddingStrategy,
      };
    case types.GET_BIDDING_STRATEGY_CARD_DATA:
      return {
        ...state,
        biddingStrategyCard: actions.biddingStrategyCard,
      };
    default:
      return state;
  }
}
