import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

import { Button as MuiButton } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { DatePickerWrapper } from "./DatePicker.style";

import OnBlurClick from "../OnBlurClick";
import { useSelector } from "react-redux";
import { getDate } from "../../redux/selector/themeSelector";
const Button = styled(MuiButton)(spacing);

type Dates = {
  sDate: Date;
  eDate: Date;
};

export interface IDatepicker {
  DateChange: (dates: Dates) => void;
}

const DatePickerCard = ({ DateChange }: IDatepicker) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [date, setDate] = useState("");
  const [show, setShow] = useState(false);
  const dates = useSelector(getDate);

  const handleChange = (dates: [Date, Date]) => {
    let [start, end] = dates;
    setDate(
      dayjs(start).format("DD MMM YYYY") +
        " - " +
        dayjs(end).format("DD MMM YYYY")
    );
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      end.setHours(23, 59, 59, 0);
      start.setHours(0, 0, 0, 0);
      DateChange({ sDate: start, eDate: end });
      setShow(false);
    }
  };
  useMemo(() => {
    setDate(
      dayjs(dates.sDate).format("DD MMM YYYY") +
        " - " +
        dayjs(dates.eDate ? dates.eDate : "").format("DD MMM YYYY")
    );
    setStartDate(dates.sDate);
    setEndDate(dates.eDate);
  }, [dates]);

  const buttonPicker = (type: number) => {
    let eDate = new Date();
    let sDate = new Date();

    if (type === 1) {
      eDate.setDate(eDate.getDate() - 1);
      sDate.setDate(sDate.getDate() - 1 * 7);
      sDate.setHours(0, 0, 0, 0);
      eDate.setHours(23, 59, 59, 0);
    } else if (type === 2) {
      eDate.setDate(eDate.getDate() - 1);
      sDate.setMonth(sDate.getMonth() - 1);
      sDate.setHours(0, 0, 0, 0);
      eDate.setHours(23, 59, 59, 0);
    } else if (type === 3) {
      sDate.setDate(sDate.getDate() - 1);
    }

    let start = sDate;
    let end = eDate;
    setStartDate(sDate);
    setEndDate(eDate);
    setShow(false);
    setDate(
      dayjs(sDate).format("DD MMM YYYY") +
        " - " +
        dayjs(eDate).format("DD MMM YYYY")
    );
    DateChange({ sDate: start, eDate: end });
  };

  return (
    <DatePickerWrapper>
      <div className="dateInnerBox">
        <div className="chooseBox">
          <input
            value={date}
            onClick={() => {
              setShow(!show);
            }}
            onChange={() => {
              setShow(!show);
            }}
            type="text"
            placeholder={"Choose Date"}
          />
        </div>
        {show && (
          <OnBlurClick setShow={setShow}>
            <div className="buttonBox">
              <DatePicker
                startDate={startDate}
                onChange={handleChange}
                selected={startDate}
                endDate={endDate}
                dropdownMode={"select"}
                inline
                monthsShown={2}
                selectsRange
                maxDate={new Date()}
              />
              <div className="bottomBotton">
                <Button
                  mr={2}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    buttonPicker(3);
                  }}
                >
                  {"Past 24 hours"}
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    buttonPicker(1);
                  }}
                >
                  {"Past Week"}
                </Button>
                <Button
                  mr={2}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    buttonPicker(2);
                  }}
                >
                  {"Past Month"}
                </Button>
              </div>
            </div>
          </OnBlurClick>
        )}
      </div>
    </DatePickerWrapper>
  );
};

export default DatePickerCard;
