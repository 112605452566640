import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import campaignReducer from "./campaignReducer";
import AdGroupReducer from "./adGroupReducer";
import AccountSettingReducer from "./accountSettingReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  campaignReducer,
  AdGroupReducer,
  AccountSettingReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
