import * as types from "../../constants";
import {
  ConversionByIdObject,
  ConversionListObject,
  ManagerListObject,
  UserListObject,
} from "../../types/accountsetting";

export type accountSettingType = {
  customerUserAccess: UserListObject[];
  customerManagerLink: ManagerListObject[];
  conversion: ConversionListObject[];
  conversionById: ConversionByIdObject[];
  create: {
    message: string;
  };
};

const initialState: accountSettingType = {
  customerUserAccess: [],
  customerManagerLink: [],
  conversion: [],
  conversionById: [],
  create: {
    message: "",
  },
};

export default function reducer(
  state = initialState,
  actions: accountSettingType & { type: string }
): accountSettingType {
  switch (actions.type) {
    case types.GET_USERLIST_DATA:
      return {
        ...state,
        customerUserAccess: actions.customerUserAccess,
      };
    case types.GET_MANAGERLIST_DATA:
      return {
        ...state,
        customerManagerLink: actions.customerManagerLink,
      };
    case types.GET_CONVERSIONLIST_DATA:
      return {
        ...state,
        conversion: actions.conversion,
      };
    case types.GET_CONVERSION_DATA_BY_ID:
      return {
        ...state,
        conversionById: actions.conversionById,
      };
    case types.CREATE_CONVERSION_SUCCESS:
      return {
        ...state,
        create: actions.create,
      };
    default:
      return state;
  }
}
