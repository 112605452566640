import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  withRouter,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";

// Material imports
import SettingsIcon from "@material-ui/icons/Settings";
import FolderIcon from "@material-ui/icons/Folder";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Collapse, Grid } from "@material-ui/core";

// Types
import { RouteType, RouteChildType } from "../../types/routes";
import { Campaigns } from "../../types/campaign";

// Redux
import { getAllCampaign } from "../../redux/actions/campaignActions";
import { fetchAllCampaignList } from "../../redux/selector/campaignSelector";

// Components
import {
  sidebarRoutes as routes,
  subMenuRoutes,
  AccountSettingRoutes,
} from "../../routes/index";

// Styles
import "../../vendor/perfect-scrollbar.css";
import {
  List,
  Category,
  CategoryBadge,
  Link,
  CategoryIconLess,
  CategoryIconMore,
  CategoryText,
  Drawer,
  Items,
  LinkBadge,
  LinkText,
  Scrollbar,
  SidebarFooter,
  SidebarFooterText,
  SidebarSection,
  Divider,
} from "./SideBar.style";

type SidebarCategoryPropsType = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  isCollapsable: boolean;
  badge?: string | number;
  activeClassName?: string;
  button: true;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
  menutype: number;
  isDisable: boolean;
  CampId?: string;
  status?: string;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  isDisable,
  CampId,
  status,
  ...rest
}) => {
  const url = window.location.href;
  const getClass = () => {
    let className = "";
    if (status === "REMOVED") {
      className += "removed ";
    }

    if (CampId && url.includes(CampId)) {
      className += "active";
    }

    if (rest.menutype === 1) {
      className += " sidebarClass";
    }
    return className;
  };
  return (
    <Category className={getClass()} disabled={isDisable} {...rest}>
      {icon}
      <CategoryText menutype={rest.menutype}>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

type SidebarLinkPropsType = {
  name: string;
  to: string;
  badge?: string | number;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({ name, to, badge }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  routes: Array<RouteType>;
  PaperProps: {
    style: {
      width: number;
      left: number;
      top: string;
      height: string;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  menutype: number;
  onClose?: () => void;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes,
  staticContext,
  location,
  menutype,
  ...rest
}) => {
  const dispatch = useDispatch();
  const campaingsList = useSelector(fetchAllCampaignList);
  type tplotOptions = {
    [key: number]: boolean;
  };
  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route: RouteType, index: number) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const history = useHistory();
  const path = "/new-campaign";

  useMemo(() => {
    if (campaingsList.length === 0 && menutype === 1) {
      dispatch(getAllCampaign({ pageSize: 10000, nextPage: "" }));
    }
  }, [campaingsList.length, dispatch, menutype]);

  const toggle = (index: number) => {
    Object.keys(openRoutes).forEach(
      (item: string) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes: tplotOptions) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    setOpenRoutes((openRoutes: tplotOptions) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const getMenuType = () => {
    const index = AccountSettingRoutes.findIndex(
      (res: RouteType) => res.path === history.location.pathname
    );
    if (index > -1) {
      return "accounts";
    }
    return "default";
  };

  const checkIsMenuActive = (category: RouteType) => {
    if (getMenuType() === "default") {
      if (category.path.includes("view-campaign")) {
        return true;
      }
      if (category.path === path && history.location.pathname === path) {
        return true;
      } else {
        if (history.location.pathname === path || category.path === path) {
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  };

  const getId = (path: string) => {
    const historyArray = history.location.pathname.split("/");
    const count = historyArray.length;
    const id = historyArray[count - (count === 4 ? 2 : 1)];
    if (parseInt(id) > 0) {
      path = path.replace(":id", id);
    }
    return path;
  };

  const checkMenuIsDisable = () => {
    if (getMenuType() === "default") {
      const historyArray = history.location.pathname.split("/");
      const count = historyArray.length;
      const id = historyArray[count - (count === 4 ? 2 : 1)];
      if (parseInt(id) > 0) {
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    let el: Element | null = document.querySelector(".sidebarClass.active");
    if (el)
      el.scrollIntoView({
        block: "center",
      });
  });

  return (
    <Drawer variant="permanent" {...rest}>
      <Scrollbar menutype={menutype}>
        <List disablePadding menutype={menutype}>
          <Items className="CUSTOMSCROLL">
            {menutype === 1 && (
              <>
                <SidebarCategory
                  isCollapsable={false}
                  name={"All Campaigns"}
                  to={"/campaign"}
                  activeClassName="active"
                  component={NavLink}
                  icon={<FolderIcon />}
                  exact
                  button
                  menutype={menutype}
                  isDisable={false}
                />

                {campaingsList.map((res: Campaigns, index: number) => (
                  <SidebarCategory
                    key={index}
                    isCollapsable={false}
                    name={res.campaign.name}
                    to={`/alladgroup/${res.campaign.id}`}
                    activeClassName="active"
                    component={NavLink}
                    icon={<FolderIcon />}
                    exact
                    button
                    menutype={menutype}
                    isDisable={false}
                    CampId={res.campaign.id}
                    status={res.campaign.status}
                  />
                ))}
                <SidebarCategory
                  isCollapsable={false}
                  name={"New Campaign"}
                  to={"/new-campaign"}
                  activeClassName="active"
                  component={NavLink}
                  icon={<AddCircleOutlineIcon />}
                  exact
                  button
                  menutype={menutype}
                  isDisable={false}
                />
              </>
            )}

            {menutype === 2 &&
              (getMenuType() === "accounts"
                ? AccountSettingRoutes
                : subMenuRoutes
              ).map((category: RouteType, index: number) => (
                <React.Fragment key={index}>
                  {category.header ? (
                    <SidebarSection>{category.header}</SidebarSection>
                  ) : null}
                  {category.children && category.icon ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                        menutype={menutype}
                        isDisable={!checkIsMenuActive(category)}
                      />
                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map(
                          (route: RouteChildType, index: number) => (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              badge={route.badge}
                            />
                          )
                        )}
                      </Collapse>
                    </React.Fragment>
                  ) : category.icon && category.id !== "New Coversion" ? (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={`${getId(category.path)}`}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                      menutype={menutype}
                      isDisable={
                        !checkIsMenuActive(category) ||
                        !checkMenuIsDisable() ||
                        Boolean(category.isDisable)
                      }
                    />
                  ) : null}
                  {category.showDevider && (
                    <Divider style={{ margin: "0  15px" }} />
                  )}
                </React.Fragment>
              ))}
          </Items>
        </List>
      </Scrollbar>
      {menutype === 1 && (
        <SidebarFooter>
          <Grid container>
            <Grid item>
              <SidebarFooterText
                variant="body2"
                className={getMenuType() === "accounts" ? "active" : ""}
                onClick={() => {
                  history.push("/accountsettings");
                }}
              >
                <SettingsIcon />
                Account Settings
              </SidebarFooterText>
            </Grid>
          </Grid>
        </SidebarFooter>
      )}
    </Drawer>
  );
};

export default withRouter(Sidebar);
