import * as React from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Tooltip, IconButton as MuiIconButton } from "@material-ui/core";

import { googleLogout } from "@react-oauth/google";

import { resetClientId } from "../redux/actions/authActions";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Tooltip title="Logout">
        <IconButton
          aria-owns={undefined}
          aria-haspopup="true"
          onClick={() => {
            googleLogout();
            localStorage.clear();
            sessionStorage.clear();
            dispatch(resetClientId());
            toast.error("Logout successfully");
            history.push("/sign-in");
          }}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default UserDropdown;
