import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../types/styles";
import { RouteType } from "../types/routes";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
} from "@material-ui/core";
import { isWidthUp } from "@material-ui/core/withWidth";

import { getCreateCampaignStatus } from "../redux/selector/campaignSelector";
import { getADGroupSuccess } from "../redux/selector/adGroupSelector";
import StorageUtil from "../utils/storageUtil";
import { GoogleSignIn } from "../utils/googleSignIn";

const drawerWidth = 190;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #f7f7f7;
    .confirm-box {
      position: fixed !important;
      z-index: 99999;
    }
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  position: relative;
`;

const Drawer = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  border-top: 1.5px solid #00000054;
  border-top-right-radius: 35px;
  top: 64px;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth * 2}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 65px;
  width: calc(100% - ${drawerWidth * 2}px);
  left: ${drawerWidth * 2}px;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: #f7f7f7;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  text-align: center;
`;

type DashboardPropsType = {
  routes: Array<RouteType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
};

const Dashboard: React.FC<DashboardPropsType> = ({
  children,
  routes,
  width,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const campaignSuccess = useSelector(getCreateCampaignStatus);
  const agGroupSuccess = useSelector(getADGroupSuccess);
  const [showLoader, setShowLoader] = useState(false);

  const onSuccessFun = () => {
    setShowLoader(false);
  };
  const { signIn } = GoogleSignIn(onSuccessFun);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (campaignSuccess.message.length > 0) {
      if (campaignSuccess.message === "SUCCESS") {
        toast.success("Campaign Added Successfully!");
      }
    }
  }, [campaignSuccess]);

  useEffect(() => {
    if (agGroupSuccess.message.length > 0) {
      if (agGroupSuccess.message === "SUCCESS") {
        toast.success("Ad Group Added Successfully!");
      } else {
        toast.error("Something went wrong!");
      }
    }
  }, [agGroupSuccess]);

  useEffect(() => {
    const token = StorageUtil.getLocalStorage("token");
    if (!token.expirationTime || token.expirationTime <= new Date().getTime()) {
      setShowLoader(true);
      signIn();
    }
  }, [signIn]);

  return (
    <>
      <Header onDrawerToggle={handleDrawerToggle} />
      {showLoader ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <Drawer>
            <Hidden mdUp implementation="js">
              {/* <Sidebar
              routes={routes}
              PaperProps={{
                style: {
                  width: drawerWidth,
                  left: drawerWidth,
                  top: "65px",
                  height: "calc(100% - 65px)",
                },
              }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              menutype={1}
            /> */}
              {/* <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          /> */}
            </Hidden>
            <Hidden smDown implementation="css">
              <Sidebar
                routes={routes}
                PaperProps={{
                  style: {
                    width: drawerWidth,
                    left: 0,
                    top: "65px",
                    height: "calc(100% - 65px)",
                  },
                }}
                menutype={1}
              />
              <Sidebar
                routes={routes}
                PaperProps={{
                  style: {
                    width: drawerWidth,
                    left: drawerWidth,
                    top: "65px",
                    height: "calc(100% - 65px)",
                  },
                }}
                menutype={2}
              />
            </Hidden>
          </Drawer>
          <AppContent>
            <MainContent p={isWidthUp("lg", width) ? 4 : 5}>
              {children}
            </MainContent>
            <Footer />
          </AppContent>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
        </Root>
      )}
    </>
  );
};

export default withWidth()(Dashboard);
