import * as types from "../../constants";
import { SetThemeType } from "../actions/themeActions";
import { THEMES } from "../../constants";

export type ThemeInitialStateType = {
  currentTheme: string;
  Date: {
    sDate: Date;
    eDate: Date;
  };
};

export type CustomType = {
  Date: {
    sDate: Date;
    eDate: Date;
  };
};

let date = new Date();
date.setDate(date.getDate() - 15);

const initialState = {
  currentTheme: THEMES.DEFAULT,
  Date: {
    sDate: date,
    eDate: new Date(),
  },
};

export default function reducer(
  state = initialState,
  actions: ActionTypes & { type: string } & CustomType
): ThemeInitialStateType {
  switch (actions.type) {
    case types.THEME_SET:
      return {
        ...state,
        currentTheme: actions.payload,
      };
    case types.SET_DATE:
      return {
        ...state,
        Date: actions.Date,
      };

    default:
      return state;
  }
}

type ActionTypes = SetThemeType;
