import * as React from "react";

import Loader from "./Loader";

const sleep = (m: number) => new Promise((r) => setTimeout(r, m));
type comp = () => {
  name: String;
};

type componentType = React.ComponentClass<comp> | null;

interface IAsyncState {
  component: componentType;
}
// TODO Husain remove any later because currently not clear what will come here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function asyncComponent(importComponent: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  class AsyncComponent extends React.Component<any, IAsyncState> {
    constructor(props: React.ReactPropTypes) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      await sleep(process.env.NODE_ENV === "development" ? 150 : 0);

      const { default: component } = await importComponent();

      this.setState({
        component: component,
      });
    }

    render() {
      const C: componentType = this.state.component;
      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}
