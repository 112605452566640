export default class StorageUtil {
  private constructor() {}

  // used to set localstorage item
  static setLocalStorage(key: string, value: any): void {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  // used to get localstorage item
  static getLocalStorage(key: string): any {
    const data: string | null = key ? localStorage.getItem(key) : null;
    return data ? JSON.parse(data) : data;
  }

  // used to remove localstorage item
  static removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  // used to clear localstorage
  static clearLocalStorage(): void {
    localStorage.clear();
  }
}
