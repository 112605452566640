import React from "react";

import async from "../components/Async";

import { Users } from "react-feather";
import FolderIcon from "@material-ui/icons/Folder";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const AuthGuard = async(() => import("../components/AuthGuard"));
const GuestGuard = async(() => import("../components/GuestGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
// const Default = async(() => import("../pages/dashboards/Default"));

// const AdGroup = async(() => import("../pages/adGroup"));
const AllCampaign = async(() => import("../pages/allCampaigns"));
const CreateCampaign = async(() => import("../pages/createCampaign"));
const ViewCampaign = async(() => import("../pages/viewCampaign"));
const CreateAdGroup = async(() => import("../pages/createAdGroup"));
const AllAdGroup = async(() => import("../pages/allAdGroup"));
const AllAdGroupAd = async(() => import("../pages/allAdGroupAd"));
const AllKeyword = async(() => import("../pages/allKeyword"));
const SearchTerm = async(() => import("../pages/searchTerm"));
const BiddingStrategy = async(() => import("../pages/biddingStrategy"));
const AccountSettings = async(() => import("../pages/accountSettings"));
const AccountSettingsConversation = async(
  () => import("../pages/accountSettingsConversation")
);
const CreateConversion = async(() => import("../pages/CreateConversion"));

const ViewAdGroup = async(() => import("../pages/viewAdGroup"));
// const landingPage = {
//   id: "landing",
//   path: "/",
//   icon: <Sliders />,
//   component: Default,
//   children: null,
// };

const AdGroupRoutes = {
  id: "All Campaigns",
  path: "/campaign",
  icon: <FolderIcon />,
  component: AllCampaign,
  children: null,
  guard: AuthGuard,
};
const AddCampaignRoutes = {
  id: "New Campaign",
  path: "/new-campaign",
  icon: <AddCircleOutlineIcon />,
  component: CreateCampaign,
  children: null,
  guard: AuthGuard,
};
const CreateAdGroupRoutes = {
  id: "Ad Group",
  path: "/createadgroup/:id",
  icon: <></>,
  component: CreateAdGroup,
  children: null,
  guard: AuthGuard,
};
const AccountSettingsRoutes = {
  id: "Access & Security",
  path: "/accountsettings",
  icon: <ArrowRightIcon />,
  component: AccountSettings,
  children: null,
  guard: AuthGuard,
};
const AccountSettingsConversationRoutes = {
  id: "Conversions",
  path: "/Conversions",
  icon: <ArrowRightIcon />,
  component: AccountSettingsConversation,
  children: null,
  guard: AuthGuard,
};
const AddConversionRoutes = {
  id: "New Conversion",
  path: "/new-conversion",
  icon: <ArrowRightIcon />,
  component: CreateConversion,
  children: null,
  guard: AuthGuard,
};
const ViewAdGroupRoutes = {
  id: "View Ad Group",
  path: "/viewadgroup/:campaignId/:id",
  icon: <AddCircleOutlineIcon />,
  component: ViewAdGroup,
  children: null,
  guard: AuthGuard,
};
const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/sign-in",
      name: "Sign In",
      component: SignIn,
      guard: GuestGuard,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const allAdGroupsRoutes = {
  id: "All Ad Groups",
  path: "/alladgroup/:id",
  icon: <ArrowRightIcon />,
  component: AllAdGroup,
  children: null,
  showDevider: true,
  guard: AuthGuard,
};

const adsExntensionRoutes = {
  id: "Ads and extensions",
  path: "/alladgroupad/:id",
  icon: <ArrowRightIcon />,
  component: AllAdGroupAd,
  children: null,
  guard: AuthGuard,
};

const keywordsRoutes = {
  id: "Keywords",
  path: "/allKeyword/:id",
  icon: <ArrowRightIcon />,
  component: AllKeyword,
  children: null,
  guard: AuthGuard,
};

const searchTermRoutes = {
  id: "Search Term",
  path: "/searchTerm/:id",
  icon: <ArrowRightIcon />,
  component: SearchTerm,
  children: null,
  guard: AuthGuard,
};

const biddingStrategyRoutes = {
  id: "Bidding Strategy",
  path: "/biddingStrategy/:id",
  icon: <ArrowRightIcon />,
  component: BiddingStrategy,
  children: null,
  guard: AuthGuard,
};

const locationRoutes = {
  id: "Locations",
  path: "/",
  icon: <ArrowRightIcon />,
  component: AllCampaign,
  children: null,
  guard: AuthGuard,
  isDisable: true,
};

const devicesRoutes = {
  id: "Devices",
  path: "/",
  icon: <ArrowRightIcon />,
  component: AllCampaign,
  children: null,
  showDevider: true,
  guard: AuthGuard,
  isDisable: true,
};

const generalSettingRoutes = {
  id: "General Settings",
  path: "/view-campaign/:id",
  icon: <ArrowRightIcon />,
  component: ViewCampaign,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  AdGroupRoutes,
  AddCampaignRoutes,
  allAdGroupsRoutes,
  adsExntensionRoutes,
  keywordsRoutes,
  searchTermRoutes,
  biddingStrategyRoutes,
  CreateAdGroupRoutes,
  AccountSettingsRoutes,
  AccountSettingsConversationRoutes,
  generalSettingRoutes,
  ViewAdGroupRoutes,
  AddConversionRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [AdGroupRoutes, AddCampaignRoutes];

export const subMenuRoutes = [
  allAdGroupsRoutes,
  adsExntensionRoutes,
  keywordsRoutes,
  searchTermRoutes,
  biddingStrategyRoutes,
  locationRoutes,
  devicesRoutes,
  generalSettingRoutes,
];

export const AccountSettingRoutes = [
  AccountSettingsRoutes,
  AccountSettingsConversationRoutes,
  AddConversionRoutes,
];
