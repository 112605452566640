import { AppStateType } from "../reducers";

export const fetchCampaignList = (state: AppStateType) =>
  state.campaignReducer.campaigns;

export const fetchAllCampaignList = (state: AppStateType) =>
  state.campaignReducer.allCampaigns;

export const getCreateCampaignStatus = (state: AppStateType) =>
  state.campaignReducer.create;

export const fetchAllLocationList = (state: AppStateType) =>
  state.campaignReducer.allLocation;

export const getAllLanguages = (state: AppStateType) =>
  state.campaignReducer.languages;

export const fetchAllCountries = (state: AppStateType) =>
  state.campaignReducer.countries;

export const fetchSitelinkList = (state: AppStateType) =>
  state.campaignReducer.allSitelink;

export const fetchCallOutList = (state: AppStateType) =>
  state.campaignReducer.allCallout;

export const fetchCallExtensionList = (state: AppStateType) =>
  state.campaignReducer.allCallExt;

export const fetchCampaignById = (state: AppStateType) =>
  state.campaignReducer.campaign;

export const fetchBiddingOptionList = (state: AppStateType) =>
  state.campaignReducer.allBiddingData;

export const fetchGeoTargets = (state: AppStateType) =>
  state.campaignReducer.geoTargets;

export const fetchCampaignCardList = (state: AppStateType) =>
  state.campaignReducer.campaignsCard;

export const fetchCreterionList = (state: AppStateType) =>
  state.campaignReducer.criterion;

export const fetchLanguageId = (state: AppStateType) =>
  state.campaignReducer.langaugesId;

export const fetchLocationId = (state: AppStateType) =>
  state.campaignReducer.locationId;

export const fetchCampaignCharts = (state: AppStateType) =>
  state.campaignReducer.campaignCharts;

export const fetchkeywordCharts = (state: AppStateType) =>
  state.campaignReducer.keywordCharts;

export const fetchAdsChart = (state: AppStateType) =>
  state.campaignReducer.AdsCharts;

export const updateCampaignNameStatus = (state: AppStateType) =>
  state.campaignReducer.updatename;

export const updateCampaignNetworkStatus = (state: AppStateType) =>
  state.campaignReducer.updatenetwork;

export const updateCampaignBudgetStatus = (state: AppStateType) =>
  state.campaignReducer.updatebudget;

export const updateCamapaignLanguages = (state: AppStateType) =>
  state.campaignReducer.updateLanguages;

export const updateCampaignUrl = (state: AppStateType) =>
  state.campaignReducer.updateUrl;

export const updateCamoaignExtension = (state: AppStateType) =>
  state.campaignReducer.updateExtension;

export const updateBiddingResponse = (state: AppStateType) =>
  state.campaignReducer.updateBidding;

export const updateLocationResponse = (state: AppStateType) =>
  state.campaignReducer.updateLocation;

export const fetchAllStrategies = (state: AppStateType) =>
  state.campaignReducer.biddingStrategies;

export const fetchSearchTermCharts = (state: AppStateType) =>
  state.campaignReducer.searchTermCharts;

export const fetchBiddingStratrgyChart = (state: AppStateType) =>
  state.campaignReducer.biddingStrategyCharts;
