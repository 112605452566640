import * as types from "../../constants";

export type SetThemeType = {
  type: string;
  payload: string;
};
export function setTheme(value: string): SetThemeType {
  return {
    type: types.THEME_SET,
    payload: value,
  };
}

export function setDate(date: { sDate: Date; eDate: Date }) {
  return {
    type: types.SET_DATE,
    Date: date,
  };
}
