import { AppStateType } from "../reducers";

export const fetchAdGoup = (state: AppStateType) =>
  state.AdGroupReducer.adGroups;

export const fetchCallOutList = (state: AppStateType) =>
  state.AdGroupReducer.allCallout;

export const getCallCout = (state: AppStateType) =>
  state.AdGroupReducer.campaignCallout;

export const getSiteLinks = (state: AppStateType) =>
  state.AdGroupReducer.siteLinks;

export const getWebsiteData = (state: AppStateType) =>
  state.AdGroupReducer.websiteData;

export const getWebsites = (state: AppStateType) =>
  state.AdGroupReducer.websites;

export const fetchAdGoupAds = (state: AppStateType) =>
  state.AdGroupReducer.adGroupsAds;

export const fetchAdKeyword = (state: AppStateType) =>
  state.AdGroupReducer.adKeyword;

export const fetchGroupCardList = (state: AppStateType) =>
  state.AdGroupReducer.groupCard;

export const fetchKeywordCardList = (state: AppStateType) =>
  state.AdGroupReducer.keywordCard;

export const fetchAdCardList = (state: AppStateType) =>
  state.AdGroupReducer.adCard;

export const fetchExtenstions = (state: AppStateType) =>
  state.AdGroupReducer.extensions;

export const getADGroupSuccess = (state: AppStateType) =>
  state.AdGroupReducer.create;

export const fetchGroupCharts = (state: AppStateType) =>
  state.AdGroupReducer.groupCharts;

export const fetchGroupById = (state: AppStateType) =>
  state.AdGroupReducer.group;

export const fetchKeywordId = (state: AppStateType) =>
  state.AdGroupReducer.keyword;

export const fetchAdsId = (state: AppStateType) =>
  state.AdGroupReducer.adGroupAd;

export const updateGroupNameStatus = (state: AppStateType) =>
  state.AdGroupReducer.updatename;

export const updateGroupAdRotationStatus = (state: AppStateType) =>
  state.AdGroupReducer.updateadrotation;

export const updateGroupAdStatus = (state: AppStateType) =>
  state.AdGroupReducer.updateads;

export const updateKeywordsResponse = (state: AppStateType) =>
  state.AdGroupReducer.updateKeywords;

export const fetchSearchTerm = (state: AppStateType) =>
  state.AdGroupReducer.searchTerm;

export const fetchSearchTermCardList = (state: AppStateType) =>
  state.AdGroupReducer.searchTermCard;

export const fetchBiddingStratrgy = (state: AppStateType) =>
  state.AdGroupReducer.biddingStrategy;

export const fetchBiddingStrategyCardList = (state: AppStateType) =>
  state.AdGroupReducer.biddingStrategyCard;
