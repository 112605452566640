import React, { useRef, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

function useOutsideAlerter(
  ref: React.RefObject<HTMLInputElement>,
  setShow: (show: boolean) => void
) {
  useEffect(() => {
    function handleClickOutside(event: AnyEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setShow]);
}

type blurType = {
  setShow: (show: boolean) => void;
};

const OnBlurClick: React.FC<blurType> = ({ setShow, children }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setShow);
  return <div ref={wrapperRef}>{children}</div>;
};

export default OnBlurClick;
