import * as types from "../../constants";

export type UserType = {
  id?: string;
  email?: string;
  name?: string;
};

export type AuthType = {
  user?: UserType | undefined;

  clientId?: string;
};

export default function reducer(state = {}, actions: any): AuthType {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          name: actions.name,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };
    case types.GET_CLIENT_ID:
      return {
        ...state,
        clientId: actions.clientId,
      };
    default:
      return state;
  }
}
